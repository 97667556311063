.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  align-items: center;
}

.fireworks {
  position: absolute;
  width: 100%;
  height: 100%;
}

.lang {
  position: absolute;
  right: 10px;
  top: 10px;
}

.content {
  position: relative;
  top: 25%;
  z-index: 2;
}

.header {
  margin-bottom: 20px;
  text-align: center;
}

.title {
  font-size: 32px;
}

.main {
  width: 368px;
}

.button {
  width: 100%;
}

.login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 320px;
  height: 320px;
  padding: 36px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
}

.login-form button {
  width: 100%;
}

.login-form p {
  color: #cccccc;
  text-align: center;
  margin-top: 16px;
}

.login-form p span:first-child {
  margin-right: 16px;
}

.logo {
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  margin-bottom: 24px;
}

.logo img {
  width: 40px;
  margin-right: 8px;
}

.logo span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
}

.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}

@media (max-width: 768px) {
  .content {
    padding: 32px 0 24px;
  }

  .main {
    width: 95%;
  }
}
