html,
body {
  background-color: #f1f2f5;
}

/* .page-content .ant-col {
  flex: 1 1 auto;
} */

.ant-card>.ant-card-body>.ant-row>.ant-col {
  flex: 1 1 auto;
}

.ant-tooltip {
  max-width: inherit;
}

.g2-tooltip {
  background-color: rgba(0, 0, 0, 0.8) !important;
  color: #fff !important;
}

.ant-card-extra {
  padding: 0;
}

.mySuspence {
  margin-top: 16px;
}

.ant-tabs-bar {
  padding: 0 24px;
  margin: 0 0 24px 0;
  border-bottom: 1px solid #e8e8e8;
  background: #fff;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background-color: #f0f2f5;
  border-bottom-color: #f0f2f5;
}

.ant-form-inline .ant-form-item:last-child {
  margin-right: 0;
}

.clean-to-time-range-picker .ant-calendar-time-picker-btn {
  display: none !important;
}

.clean-to-time-range-picker .ant-calendar-time-picker-inner {
  padding-top: 0 !important;
}

.ant-table tbody>tr.table-row-highlight>td {
  background: #f5f5f5;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 5px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.1);
}

.ant-table-tbody>tr>td {
  word-break: break-all;
}

@media screen and (max-width: 1200px) {

  .ant-card-head,
  .ant-card-body {
    padding: 16px !important;
  }
}

@media screen and (max-width: 576px) {
  .ant-card-head {
    padding: 0 16px 0 16px !important;
  }
}