.header {
  padding: 0 12px 0 0;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}

.trigger {
  font-size: 20px;
  line-height: 64px;
  cursor: pointer;
  transition: all .3s;
  padding: 0 24px;
}

.trigger:hover {
  background: #e6f7ff;
}

.content {
  margin: 24px;
}

@media screen and (max-width: 599px) {
  .content {
    margin: 12px 0;
  }
}
